<template>
  <div>
    <div class="mb-7">
      <div class="d-flex flex-wrap flex-stack mb-6">
        <h3 class="mb-0">Contracts</h3>
        <div class="d-flex flex-wrap" />
      </div>
      <pxs-proposals proposal-status="Signed" :excludedHeaders="['construction', 'address', 'city']" />
    </div>

    <div class="mb-7">
      <div class="d-flex flex-wrap flex-stack mb-6">
        <h3 class="mb-0">Proposals</h3>
        <div class="d-flex flex-wrap" />
      </div>
      <pxs-proposals proposal-status="not.Signed" :excludedHeaders="['construction', 'address', 'city']" />
    </div>

    <div>
      <div class="d-flex flex-wrap flex-stack mb-6">
        <h3 class="mb-0">Change Orders</h3>
        <div class="d-flex flex-wrap" />
      </div>
      <pxs-change-orders :excluded-headers="['title']" />
    </div>
  </div>
</template>

<script>
import PxsProposals from '@/sections/PxsProposals';
import PxsChangeOrders from '@/sections/PxsChangeOrders';

export default {
  components: {
    PxsProposals,
    PxsChangeOrders,
  },
};
</script>
